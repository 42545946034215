import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { LeanConversationUser } from '../../../entities/lean-conversation-user.model';
import { MatDialog } from '@angular/material/dialog';
import { EapInfoDialogComponent } from './eap-info-dialog.component';

@Component({
  selector: 'app-eap-banner',
  templateUrl: './eap-banner.component.html',
  styleUrls: ['./eap-banner.component.scss'],
  imports: [TranslateModule, NgIf, MatTooltipModule, MatIconModule],
})
export class EapBannerComponent implements OnInit, OnChanges {
  @Input() otherUser: LeanConversationUser;

  isEapAllowed = false;
  showEapInfo = false;
  remainingSessionsCount = 0;
  eapCompanyName = '';

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.updateEapInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.otherUser) {
      this.updateEapInfo();
    }
  }

  private updateEapInfo(): void {
    if (!this.otherUser) {
      this.isEapAllowed = false;
      this.showEapInfo = false;
      return;
    }

    this.isEapAllowed = !this.otherUser.cannot_book_eap_session_reason;

    if (
      this.otherUser.max_eap_sessions_remaining !== undefined ||
      this.otherUser.eap_access_code_company_name
    ) {
      this.showEapInfo = true;
      this.remainingSessionsCount =
        this.otherUser.max_eap_sessions_remaining || 0;
      this.eapCompanyName = this.otherUser.eap_access_code_company_name || '';
    } else {
      this.showEapInfo = false;
    }
  }

  openEapInfoDialog(): void {
    this.dialog.open(EapInfoDialogComponent, {
      width: '400px',
      panelClass: 'eap-info-dialog',
    });
  }
}
