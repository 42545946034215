interface StripeAddress {
  city: string | null;
  country: string | null;
  line1: string | null;
  line2: string | null;
  postal_code: string | null;
  state: string | null;
}

// This takes an address with the standard fields that stripe supplies and returns an array of strings representing
// each row of the address to be displayed for consistency
export const formatAddress = (address: StripeAddress): string[] => {
  try {
    const formattedAddress: string[] = [];

    if (!address) {
      return formattedAddress;
    }

    if (typeof address !== 'object') {
      return formattedAddress;
    }

    if (address.line1) {
      formattedAddress.push(address.line1);
    }

    if (address.line2) {
      formattedAddress.push(address.line2);
    }

    let addressLine = '';

    if (address.city) {
      addressLine += address.city;
    }

    if (address.state) {
      if (addressLine) {
        addressLine += ', ' + address.state;
      } else {
        addressLine += address.state;
      }
    }

    if (address.country) {
      if (addressLine) {
        addressLine += ', ' + address.country;
      } else {
        addressLine += address.country;
      }
    }

    if (address.postal_code) {
      if (addressLine) {
        addressLine += ' ' + address.postal_code;
      } else {
        addressLine += address.postal_code;
      }
    }

    if (addressLine) {
      formattedAddress.push(addressLine);
    }

    return formattedAddress;
  } catch (error) {
    console.error('Error formatting address:', error);
    return []; // Return empty array in case of any errors
  }
};
