import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Animation } from 'src/app/shared/animations/fade-animation';
import { Router } from '@angular/router';
import { Channel } from 'stream-chat';
import {
  ChannelService,
  ChatClientService,
  DefaultStreamChatGenerics,
  StreamChatModule,
} from 'stream-chat-angular';
import { getOtherMemberUserIfOneToOneChannel } from '../../../shared/helpers/stream_chat_helper';
import { StreamConversationPreviewComponent } from '../../../shared/components/chat/stream-conversation-preview/stream-conversation-preview.component';
import { TranslateModule } from '@ngx-translate/core';
import {
  AsyncPipe,
  NgClass,
  NgForOf,
  NgIf,
  NgTemplateOutlet,
} from '@angular/common';
import { SidenavComponent } from '../../../frame/sidenav/sidenav.component';
import { ConversationPreviewComponent } from '../../../shared/components/chat/conversation-preview/conversation-preview.component';
import { debounceTime, Observable, Subject, Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-message-stream-list',
  templateUrl: './message-list-stream.component.html',
  styleUrls: ['./message-list-stream.component.scss'],
  animations: [Animation.fadeAnimation],
  imports: [
    StreamConversationPreviewComponent,
    TranslateModule,
    StreamChatModule,
    AsyncPipe,
    SidenavComponent,
    NgTemplateOutlet,
    NgIf,
    NgForOf,
    NgClass,
    ConversationPreviewComponent,
    FormsModule,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatTooltip,
  ],
})
export class MessageListStreamComponent implements OnInit, OnDestroy {
  public isLoading: boolean = true;
  public hasError: boolean = false;

  channels$: Observable<Channel<DefaultStreamChatGenerics>[] | undefined>;
  isError$: Observable<boolean>;
  isInitializing$: Observable<boolean>;
  isLoadingMoreChannels = false;
  hasMoreChannels$: Observable<boolean>;
  loadingState: 'idle' | 'loading-top' | 'loading-bottom' = 'idle';

  public currentUser: User;
  public sorting: SortingType = 'has_unread';
  public searchTerm: string = '';
  public searchTerm$ = new Subject<string>();
  public showHidden: boolean = false;
  private channelsSubscription: Subscription;

  constructor(
    private sharedService: SharedService,
    private channelService: ChannelService,
    private chatClientService: ChatClientService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.sorting = 'last_updated';
    this.hasMoreChannels$ = this.channelService.hasMoreChannels$;

    this.initializeChannelService();

    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });

    this.channelService.channelQueryState$.subscribe((state) => {
      this.isLoading = !state || state.state === 'in-progress';
    });

    // Subscribe to searchTerm$ with debouncing
    this.searchTerm$
      .pipe(
        debounceTime(300), // wait 300ms after the last event before emitting last event
        distinctUntilChanged() // only emit if the current value is different than the last
      )
      .subscribe((term) => {
        this.searchTerm = term;
        this.initializeChannelService();
      });

    this.channels$ = this.channelService.channels$;
    this.hasMoreChannels$ = this.channelService.hasMoreChannels$;
    this.isError$ = this.channelService.channelQueryState$.pipe(
      map((s) => !this.isLoadingMoreChannels && s?.state === 'error')
    );
    this.isInitializing$ = this.channelService.channelQueryState$.pipe(
      map((s) => !this.isLoadingMoreChannels && s?.state === 'in-progress')
    );
  }

  initializeChannelService() {
    this.isLoading = true;
    this.channelService.reset();

    const filters: any = {
      type: 'messaging',
      members: { $in: [this.chatClientService.chatClient.user!.id] },
      hidden: this.showHidden,
    };

    if (this.searchTerm) {
      filters['member.user.name'] = { $autocomplete: this.searchTerm };
    }

    this.channelService.init(filters, { [this.sorting]: -1 }, null, false);
  }

  changeSorting(sortingType: SortingType) {
    this.sorting = sortingType;
    window.localStorage.setItem('icconsort', this.sorting);
    // Reinitialize the channel service with the new sorting
    this.initializeChannelService();
  }

  changeShowHidden(shouldShowHidden: boolean) {
    this.showHidden = shouldShowHidden;
    this.initializeChannelService();
  }

  onSearchTermChange(term: string) {
    this.searchTerm$.next(term); // Emit the search term
  }

  async loadMoreChannels() {
    this.isLoadingMoreChannels = true;
    await this.channelService.loadMoreChannels();
    this.isLoadingMoreChannels = false;
  }

  goToConversation(channel: Channel<DefaultStreamChatGenerics>) {
    const otherUser = getOtherMemberUserIfOneToOneChannel(
      this.currentUser,
      channel
    );
    if (!otherUser) {
      // Handle the case where no other user is found
      return;
    }
    this.router.navigate(['/home/messaging', otherUser.id]);
  }

  trackByChannelId(_: number, item: Channel<DefaultStreamChatGenerics>) {
    return item.cid;
  }

  ngOnDestroy() {
    if (this.channelsSubscription) {
      this.channelsSubscription.unsubscribe();
    }
  }
}
type SortingType =
  | 'last_updated'
  | 'last_message_at'
  | 'updated_at'
  | 'created_at'
  | 'member_count'
  | 'unread_count'
  | 'has_unread';
