import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-eap-info-dialog',
  standalone: true,
  imports: [TranslateModule, MatDialogModule, MatButtonModule, MatIconModule],
  template: `
    <h2 mat-dialog-title>
      {{ 'messaging.eap-banner.dialog.title' | translate }}
    </h2>
    <mat-dialog-content>
      <p>{{ 'messaging.eap-banner.dialog.description' | translate }}</p>
      <div class="button-container">
        <a
          [href]="'messaging.eap-banner.dialog.link_url' | translate"
          target="_blank"
          class="learn-more-link"
          mat-button
          color="primary"
        >
          {{ 'messaging.eap-banner.dialog.link_text' | translate }}
          <mat-icon>open_in_new</mat-icon>
        </a>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>
        {{ 'common.close' | translate }}
      </button>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .button-container {
        display: flex;
        justify-content: center;
        margin-top: 16px;
      }
      .learn-more-link {
        display: inline-flex;
        align-items: center;
        gap: 8px;
      }
      mat-dialog-content {
        margin-bottom: 16px;
      }
      .mat-mdc-dialog-title {
        margin-top: 16px;
      }
      .mat-mdc-dialog-title::before {
        content: none; /* This removes the ::before pseudo-element */
      }
    `,
  ],
})
export class EapInfoDialogComponent {
  constructor(public dialogRef: MatDialogRef<EapInfoDialogComponent>) {}
}
