import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Appointment } from '../../../entities/appointment.model';
import { GenericHttpService } from 'src/app/shared/services/Generic HTTP/generic-http.service';
import { LeanAppointment } from '../../../entities/lean-appointment.model';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(private http: GenericHttpService) {}

  status = {
    inquiry: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
    pending: {
      primary: '#FDF1BA',
      secondary: '#FDF1BA',
    },
    confirmed: {
      primary: 'rgb(116, 208, 191)',
      secondary: '#ecf8f6',
    },
    external: {
      primary: '#cccccc',
      secondary: '#eaeaea',
    },
    blocker: {
      primary: '#cccccc',
      secondary: '#eaeaea',
    },
  };

  public getAppointment(id: any, isTherapist: boolean): Observable<any> {
    return this.http.get('/appointments/' + id).pipe(
      map((appointment: any) => {
        // we should start creating the title on the backend based on the logged in user
        return {
          ...appointment,
          title: isTherapist
            ? appointment.therapist_facing_title
            : `${appointment.profile.firstname} ${appointment.profile.lastname}`,
        };
      })
    );
  }

  /**
   * Get appointments for a specific view (month, week, day) and date range
   * @param view Current calendar view
   * @param viewDate The current center date of the view
   * @returns Observable of calendar events
   */
  public getAppointmentsForView(
    view: CalendarView,
    viewDate: Date
  ): Observable<CalendarEvent[]> {
    const { startDate, endDate } = this.getDateRangeForView(view, viewDate);

    // Build the URL with query parameters manually
    const url = `/appointmentsV2?start=${encodeURIComponent(
      startDate.toISOString()
    )}&end=${encodeURIComponent(endDate.toISOString())}`;

    return this.http.get(url).pipe(
      map((appointments: LeanAppointment[]) => {
        return appointments.map((appointment) => {
          return {
            id: appointment.id,
            color: this.status[appointment.status],
            title: appointment.title,
            start: dayjs.utc(appointment.start).tz().toDate(),
            end: dayjs.utc(appointment.end).tz().toDate(),
            meta: appointment,
          };
        });
      })
    );
  }

  /**
   * Calculate the start and end dates for a given calendar view
   */
  private getDateRangeForView(
    view: CalendarView,
    viewDate: Date
  ): { startDate: Date; endDate: Date } {
    const date = dayjs(viewDate);
    let startDate: dayjs.Dayjs;
    let endDate: dayjs.Dayjs;

    switch (view) {
      case CalendarView.Month:
        // Added buffer: subtract 1 day from start
        startDate = date.startOf('month').startOf('week').subtract(1, 'day');
        // Added buffer: add 1 day to end
        endDate = date.endOf('month').endOf('week').add(1, 'day');
        break;
      case CalendarView.Week:
        // Added buffer: subtract 1 day from start
        startDate = date.startOf('week').subtract(1, 'day');
        // Added buffer: add 1 day to end
        endDate = date.endOf('week').add(1, 'day');
        break;
      case CalendarView.Day:
      default:
        // Added buffer: subtract 1 day from start
        startDate = date.startOf('day').subtract(1, 'day');
        // Added buffer: add 1 day to end
        endDate = date.endOf('day').add(1, 'day');
        break;
    }

    return {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    };
  }

  /**
   * @deprecated Use getAppointmentsForView instead
   */
  public getAppointments(): Observable<CalendarEvent[]> {
    return this.http.get('/appointmentsV2').pipe(
      map((appointments: LeanAppointment[]) => {
        return appointments.map((appointment) => {
          return {
            id: appointment.id,
            color: this.status[appointment.status],
            title: appointment.title,
            start: dayjs.utc(appointment.start).tz().toDate(),
            end: dayjs.utc(appointment.end).tz().toDate(),
            meta: appointment,
          };
        });
      })
    );
  }

  public getAppointmentsForDay(date: Date): Observable<any> {
    return this.http.get(
      '/appointments/day/' + date.toISOString().split('T')[0]
    );
  }

  public saveAppointment(appointment: Appointment): Observable<Appointment> {
    return this.http.post('/appointments', appointment);
  }

  public acceptAppointment(id: number): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'accepted',
    });
  }

  public cancelAppointment(id: number): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'canceled',
    });
  }

  public declineAppointment(id: number): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'declined',
    });
  }

  public markAppointmentAsCompleted(
    id: number,
    wasOnline: boolean,
    language: string,
    topic: string
  ): Observable<Appointment> {
    return this.http.put('/appointments/' + id, {
      status: 'completed',
      appointment_survey_attributes: {
        was_online: wasOnline,
        language,
        topic,
      },
    });
  }

  public updateAppointment(appointment: Partial<Appointment>): Observable<any> {
    return this.http.put('/appointments/' + appointment.id, appointment);
  }

  public deleteBlocker(id): Observable<any> {
    return this.http.delete('/appointments/' + id);
  }

  public getLatestAppointment(): Observable<Array<Appointment>> {
    return this.http.get('/latest_appointment');
  }

  public createInvoiceForAppointment(appointment): Observable<Appointment> {
    return this.http.post('/appointments/invoice', { id: appointment.id });
  }

  public getClientAppointments(): Observable<any[]> {
    return this.http.get('/client_appointments');
  }

  public getAppointmentsRequiringCompletion() {
    return this.http.get('/appointments/requiring_completion');
  }

  public getAppointmentByUid(uid: string): Observable<Appointment> {
    return this.http.get('/appointments/by_uid/' + uid);
  }
}
