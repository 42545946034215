<div class="eap-banner" *ngIf="isEapAllowed && showEapInfo">
  <div class="eap-badge">
    <a class="info-icon" (click)="openEapInfoDialog()">
      <mat-icon>info_outline</mat-icon>
    </a>
    <span class="eap-label">{{ "messaging.eap-banner.text" | translate }}</span>
  </div>

  <div class="eap-info">
    <div class="eap-sessions">
      <span class="sessions-count">{{ remainingSessionsCount }}</span>
      <span class="sessions-label">{{
        remainingSessionsCount === 1
          ? ("messaging.eap-banner.free_session_remaining" | translate)
          : ("messaging.eap-banner.free_sessions_remaining" | translate)
      }}</span>
    </div>

    <div class="eap-company">
      <span>{{ eapCompanyName }}</span>
    </div>
  </div>
</div>
